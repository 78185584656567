import React, { useEffect } from 'react';
import $ from 'jquery';
import {_getLanguage, _setLanguage} from '../lang/LanguageUtils';

const ErrorPage = () => {
    useEffect(() => {
        $('#header').css('display', 'none');
        $('#footer').css('display', 'none');
        $("#errorPage").css('height', window.innerHeight)
    }, [])
    return (
        <>
        <div id="errorPage">
            <div className='errorPage-icon'></div>
            <p text-lang='errorPage-text'>Sorry, this page is unavailable.</p>
        </div>
        </>
    );
};

export default ErrorPage;