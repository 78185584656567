import React, { useEffect } from 'react';
import $ from 'jquery';
import {_getLanguage, _setLanguage} from '../lang/LanguageUtils';

const donationMax = 600000;

const callReferralRequest = () => {
    try {
        $.ajax({
            url: process.env.REACT_APP_DONATION_URL,
            method: "GET",
            dataType:'json',
        })
        .done((res) => {
            localStorage.donationPrice = res[0].usd_price;
            $(".donationBar .triangle").css('left', (parseInt(res[0].usd) / donationMax).toFixed(3) + "%")
        })
        .fail((e) => {
            $(".donationBar .triangle").hide()
        })
    } catch (e) {
        $(".donationBar .triangle").hide()
    }
}

const displayMiningDetail = (show) => {
    if (show) {
        $("#mining-block-detail").show();
        $("#mining-block-more").hide();
    }
    else {
        $("#mining-block-detail").hide();
        $("#mining-block-more").show();
    }
}

const _modifiedDonationBar = () => {
    if(window.innerWidth <= 768) {
        $('.donationBar').childNodes.forEach(el=> {
            if(el.className == 'donationPrice') el.innerHTML = el.innerHTML.replace(' USDT','');
        })
    }
}
export const exportGuideVideo = () =>  {
    setGuideVideo();
} 
const setGuideVideo = () => {
    if(localStorage.language == 'VI') {
        $(".home-content-body iframe").attr('src','https://www.youtube.com/embed/2Jxa0vBTEsY?si=3Igpwxzuq5OdBO_B');
        $("#wallet-block-detail .left iframe").attr('src','https://www.youtube.com/embed/qiR6ncZacac?si=QNwnmFgWjP4p-Hx-');
        $("#wallet-block-detail .right iframe").attr('src','https://www.youtube.com/embed/CKl_qSqw_qc?si=Q4K_9sj_ZBAGgBww');
        $("#mining-block-detail iframe").attr('src','https://www.youtube.com/embed/eGTxQVWELV0?si=AgIF4gVKvD0ohQvY');
        $("#donation-block-detail iframe").attr('src','https://www.youtube.com/embed/pj9mwQy3TA0?si=fQmEQud5wK2dPaPD');
        $("#referral-block-detail iframe").attr('src','https://www.youtube.com/embed/jufXmPL9OuE?si=aicfw4c8iuogYBeY');
        $(".sourceCode-block-detail iframe").attr('src','https://www.youtube.com/embed/aVnwP2cEZbk?si=saiOBLyHSALwJhZi');
    } else {
        $(".home-content-body iframe").attr('src','https://www.youtube.com/embed/W7yFaeSIg58?si=zEDZHOxA_c9mr0ow');
        $("#wallet-block-detail .left iframe").attr('src','https://www.youtube.com/embed/AXPuxgYohDg?si=mjJg4xRSY1cHIjjV');
        $("#wallet-block-detail .right iframe").attr('src','https://www.youtube.com/embed/YTwIBC7koOM?si=mbiMaXKs41KDcuhx');
        $("#mining-block-detail iframe").attr('src','https://www.youtube.com/embed/Quqe8ppIwyM?si=L6fOWvCAYWUdIh1F');
        $("#donation-block-detail iframe").attr('src','https://www.youtube.com/embed/-Bet2Otn3gc?si=h4F8YvKvG7Z9Ctgc');
        $("#referral-block-detail iframe").attr('src','https://www.youtube.com/embed/EhDM4rTQ7Vs?si=RXNOmYhtex879QPK');
        $(".sourceCode-block-detail iframe").attr('src','https://www.youtube.com/embed/iIbNJxEyI2s?si=X2UdgsAMA-aISe_i');
    }
}

const clickedInstallVideo = (el) => {
    const targetParentNode = el.target.parentNode
    const targetNode = el.target
    if(targetParentNode.querySelector('.selected').attributes['value'].textContent == targetNode.attributes['value'].textContent) return;
    else {
        var targetVideo = $('#installing-block-detail iframe');
        $("#installing-block-detail .selected").removeClass('selected');
        switch (targetNode.attributes['value'].textContent) {
            case 'seed':
                localStorage.language == 'VI'
                ? targetVideo.attr('src','https://www.youtube.com/embed/aVnwP2cEZbk?si=saiOBLyHSALwJhZi')
                : targetVideo.attr('src','https://www.youtube.com/embed/iIbNJxEyI2s?si=X2UdgsAMA-aISe_i');
                $("#installing-block-detail [value=seed]").addClass('selected');
                break;
            case 'wallet':
                localStorage.language == 'VI'
                ? targetVideo.attr('src','https://www.youtube.com/embed/RQQNVBTXfk0?si=Sc2jbkjy4TadMSPX')
                : targetVideo.attr('src','https://www.youtube.com/embed/BtHJzWxtUdk?si=QdjGhkqCOutj9d_-');
                $("#installing-block-detail [value=wallet]").addClass('selected');
                break;
            case 'pool':
                localStorage.language == 'VI'
                ? targetVideo.attr('src','https://www.youtube.com/embed/gFho8PadIZQ?si=KRqsNyCqhoY-ylEA')
                : targetVideo.attr('src','https://www.youtube.com/embed/K7bISqK-xLI?si=zjVvJLBnd4v9zHpU');
                $("#installing-block-detail [value=pool]").addClass('selected');
                break;
            default:
                break;
        }
    }
}

const Guide = () => {
    useEffect(() => {
        _setLanguage();
        callReferralRequest();
        setGuideVideo();
        // displayMiningDetail(false);
        $(".headerMenu_Home").removeClass("selected");
        $(".headerMenu_How").addClass("selected")
        $(".headerMenu_Repository").removeClass("selected")
        $("#donationContent").css("height", '100%')
        $("#mining-block-hide").html(_getLanguage('mining-text-hide'))
        $("#mining-block-more").html(_getLanguage('mining-text-more'))
        $(".headerSubMenu_Donation").addClass("selectedSub")
        $(".donationStartColor").css('background-color', "#02D5E5")
        $(".donationFinishColor").css('background-color', "#E31ADE")
        $(".html-widgets-code xmp").text(`
<div>
    <a href="`+process.env.REACT_APP_URL+`0x0000111122223333444455556666777788889999">
        <img src="https://github.com/timestopeofficial/bitcoinkrypton-repository/tree/main/referral-widget/referral-widger-image01_100px.gif" alt="Let's Mining BitcoinTimeStope" />
    </a>
</div>
        `)
        // _modifiedDonationBar();
    }, [])
    return (
        <>
            <div id='guide'>
                <div className="content">
                    <div text-lang="mode-wallet" className="guide-block-title">Wallet</div>
                    <div id='wallet-block-detail' className='guide-block'>
                        <div className='left'>
                            <iframe src="https://www.youtube.com/embed/AXPuxgYohDg?si=mjJg4xRSY1cHIjjV"
                                title="Bitcoin TimeStope Video" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                            </iframe>
                        </div>
                        <div className='right'>
                            <iframe src="https://www.youtube.com/embed/YTwIBC7koOM?si=mbiMaXKs41KDcuhx"
                                title="Bitcoin TimeStope Video" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div text-lang="mode-miner" className="guide-block-title">Mining</div>
                    <div id='mining-block-detail' className='guide-block'>
                        <div className='left'>
                        <iframe src="https://www.youtube.com/embed/Quqe8ppIwyM?si=L6fOWvCAYWUdIh1F"
                            title="Bitcoin TimeStope Video" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                        </div>
                        <div className='right'>
                            <div text-lang="mining-intro">
                                The most basic way to earn Bitcoin TimeStope is to participate in Proof-of-Work (PoW)​<br/>​<br/>
                                To participate in proof of work, select Mining at the top of the webpage and you will see the screen below.
                            </div>
                            <div text-lang="mining-text">
                                By pressing the play button, you will be participating in mining Bitcoin TimeStope using your computer.​<br />
                                When you try mining, you will be connected to a mining server optimized for your computer among several mining pools, allowing multiple people to mine together.​<br />
                                As the number of miners in a mining pool increases, the efficiency of mining decreases.​<br />​<br />
                                The earlier you participate, the higher your chances of getting Bitcoin TimeStope.​<br />​<br />
                                Bitcoin TimeStope supports JavaScript machine-based mining, and since it's actively restrict mining of large-capacity super nodes is much easier than mining of the original Bitcoin. Mining is possible even in mobile environments.​<br />​<br />
                                Don't hesitate, get involved!​<br />​<br />
                            </div>
                            {/* <div text-lang="mining-text-hide" id='mining-block-hide' onClick={() => displayMiningDetail(false)}>[Hide]</div> */}
                        </div>
                    </div>
                    {/* <div text-lang="mining-text-more" id='mining-block-more' onClick={() => displayMiningDetail(true)}>[Read More]</div> */}
                </div>

                <div className="content">
                    <div className="guide-block-title">Donation</div>
                    <div id='donation-block-detail' className='guide-block'>
                        <div className='left'>
                            <iframe src="https://www.youtube.com/embed/-Bet2Otn3gc?si=h4F8YvKvG7Z9Ctgc"
                                title="Bitcoin TimeStope Video" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                            </iframe>
                        </div>
                        <div className='right'>
                            <div text-lang="donation-text">
                                You can get Bitcoin TimeStope through donation.<br />
                                Your donation will greatly help in the initial arrival of Bitcoin TimeStope.<br />
                                <br />
                                Donations can be made through USDT, and the donation address is as follows.<br />
                                <br />
                                Donation Address: 0xbc0889EBeD112B6fDc8A3Eca1cb1E009926D386f<br />
                                Donationable Coin: USDT(Ethereum, Polygon, Binance)<br />
                                How to donation: If you send USDT to the donation address above, Bitcoin TimeStope will be resent to the address you sent it to in proportion to the USDT you sent.<br />
                                <br />
                                Compensation policy based on donation<br />
                                There are a total of 3 donation stages.<br />
                                <br />
                                Stage 1 rewards 1 TIME per 1 USDT, total amount 100,000 TIME<br/>
                                Stage 2 rewards 1 TIME per 2 USDT, total amount 200,000 TIME<br/>
                                Stage 3 rewards 1 TIME per 3 USDT, total amount 300,000 TIME<br/>
                            </div>
                        </div>
                    </div>

                    <div className='donationChart'>
                        <div className='donationIcon'>
                            <div>
                                <div className='circle donationStartColor'></div>
                                <span text-lang="donation-start" className='whiteFont'>Start Donation</span>
                            </div>
                            <div>
                                <div className='circle donationFinishColor'></div>
                                <span text-lang="donation-finish" className='whiteFont'>Finish Donation</span>
                            </div>
                        </div>
                        <div className='donationBar'>
                            <span className='donationPrice'>1 USDT</span>
                            <span className='v-line'></span>
                            <span className='donationPrice'>2 USDT</span>
                            <span className='v-line'></span>
                            <span className='donationPrice' style={{"width":"60%"}}>3 USDT</span>
                            <p className='triangle'></p>
                            <div className="donationPrice-mobile">USDT</div>
                        </div>
                    </div>
                </div>

                <div className='content'>
                    <div text-lang="referral-title" className='guide-block-title'>Referral</div>
                    <div id='referral-block-detail' className='guide-block'>
                        <div className='left'>
                            <iframe src="https://www.youtube.com/embed/EhDM4rTQ7Vs?si=RXNOmYhtex879QPK"
                                title="Bitcoin TimeStope Video" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                            </iframe>
                        </div>
                        <div className='right'>
                            <div text-lang="referral-text-top">
                                With TIME, there's a plus side to everything you do —earn up to 1 TIME when you refer up to 1000 link clicks.​<br />​<br />

                                To participate, select Wallet at the top of the webpage and you will see the screen below.
                            </div>
                            <img className='sup-block referral-guide-image'/>
                        </div>
                    </div>
                    
                    <div className="sup-block">
                        <div text-lang="accounts-wallet-referral" className="sup-title">Referral Link</div>
                        <div className="address">
                            <div text-lang="referral-link-text" className="link-text">Link:</div>
                            <div className="referal-link-text">{process.env.REACT_APP_URL}0x0000111122223333444455556666777788889999</div>
                            <div className="copy-block">
                                <div className="copy-icon"></div>
                                <span text-lang="copy-link">Copy Link</span>
                            </div>
                        </div>
                        <div className="address">
                            <div className="link-text">URL Shortener:</div>
                            <div className="referal-link-text">https://pop.onl/123456</div>
                            <div className="copy-block">
                                <div className="copy-icon"></div>
                                <span text-lang="copy-link">Copy Link</span>
                            </div>
                        </div>
                    </div>
                    <div className="sup-block">
                        <div className="sup-title">HTML Widgets Code</div>

                        <div className="address">
                            <div className="html-widgets-code">
                                <xmp>
<div>
    <a href={process.env.REACT_APP_URL+"0x0000111122223333444455556666777788889999"}>
        <img src="https://github.com/timestopeofficial/bitcoinkrypton-repository/tree/main/referral-widget/referral-widger-image01_100px.gif" alt="Let's Mining BitcoinTimeStope" />
    </a>
</div>
                                </xmp>
                            </div>
                            <div className="copy-block">
                                <div className="copy-icon widgetCode"></div>
                                <span text-lang="copy-link">Copy Link</span>
                            </div>
                        </div>
                        <div className="referral-images">
                            <div className="referral-image-first selected">
                                <div className="referral-image"></div>
                                <select className="html-widget-size" disabled>
                                    <option>100px</option>
                                </select>
                            </div>
                            <div className="referral-image-second">
                                <div className="referral-image"></div>
                                <select className="html-widget-size" disabled>
                                    <option>150px</option>
                                </select>
                            </div>
                            <div className="referral-image-third">
                                <div className="referral-image"></div>
                                <select className="html-widget-size" disabled>
                                    <option>200px</option>
                                </select>
                            </div>
                            <div className="referral-image-fourth">
                                <div className="referral-image"></div>
                                <select className="html-widget-size" disabled>
                                    <option>300px</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='sup-text' text-lang="referral-text-bottom">
                        Here's how it works.<br /><br />

                        1. Create the Referral Link<br />
                        Visit the Wallet page to create the link, of which:<br />
                        - Standard Link: Share it directly with others.<br />
                        - Shortner Link: A more compact version for easy sharing.<br />
                        - Widget HTML Code: Embed this widget on your blog or website. Each click on the widget earns you Bitcoin TimeStope!<br /><br />

                        2. They click<br />
                        Send the link to your friends, personal blogs or social media.<br />
                        They click and visit TimeStope website.<br /><br />

                        3. You earn<br />
                        When your friends/traffic click, you will earn 0.005 TIME for every person who clicks on your referral link.<br/><br/>

                        - The referral reward event runs until June 30, 2025. Don't miss out!<br/>
                        - Earn 0.005 TIME for every user who participates using your referral link.<br/>
                        - Referral rewards may not accumulate if multiple requests are made consecutively from the same IP.<br/>
                        - Each IP address can participate in the referral up to 3 times per day.<br/>
                        - Referral rewards are distributed every Monday.<br/>
                        - You cannot view your total referral rewards directly, but they can be checked through the wallet address below:<br/>
                        0xf5d52cbc404d5b1d76457a67ca92901bbfa55e3c
                    </div>
                </div>

                <div className="content">
                    <div className="guide-block-title">Installing source code</div>
                    <div id='installing-block-detail' className='sourceCode-block-detail'>
                        <div className='left'>
                            <iframe src="https://www.youtube.com/embed/iIbNJxEyI2s?si=X2UdgsAMA-aISe_i"
			    	            title="Bitcoin TimeStope Video" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                            </iframe>
                        </div>
                        <div className='right'>
                            <div className='sourceCode-item-list'>
                                <div className='sourceCode-item-title'>Play List</div>
                                <div className='sourceCode-item selected' value="seed" onClick={(el) => clickedInstallVideo(el)}>Bitcoin TimeStope Seed</div>
                                <div className='sourceCode-item' value="wallet" onClick={(el) => clickedInstallVideo(el)}>Bitcoin TimeStope Wallet</div>
                                <div className='sourceCode-item' value="pool" onClick={(el) => clickedInstallVideo(el)}>Bitcoin TimeStope Pool</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Guide;