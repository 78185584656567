import React, { useEffect } from 'react';
import $ from 'jquery';
import {_getLanguage, _setLanguage} from '../lang/LanguageUtils';

const addRepositoryElement_v1 = (type, items) => {
    const repositoryElement = document.createElement('div');
    repositoryElement.className = "repository-element";

    const repositoryTitle = document.createElement('div');
    repositoryTitle.className = "repository-title";
    repositoryTitle.innerHTML = type == 'WhitePaper' ? _getLanguage('whitepaper-title') : "Bitcoin TimeStope's " + type;
    repositoryElement.appendChild(repositoryTitle);

    for (let index = 0; index < items.length; index++) {
        const repositoryBlock = document.createElement('div');
        repositoryBlock.className = "repository-block";

        const repositoryIcon = document.createElement('div');
        repositoryIcon.className = type == 'WhitePaper' ? "repository-pdf-icon" : "repository-source-code-icon";

        const repositoryInfoBlock = document.createElement('div');
        repositoryInfoBlock.className = "repository-info-entry";

        const repositoryInfoText = document.createElement('div');
        repositoryInfoText.textContent = type == 'WhitePaper' ? _getLanguage('whitepaper-title') :"Bitcoin TimeStope's";

        const repositoryInfoIcon = document.createElement('div');
        repositoryInfoIcon.className = "repository-info-block";
        repositoryInfoIcon.textContent = Object.values(items[index]);
        
        repositoryInfoBlock.appendChild(repositoryInfoText);
        repositoryInfoBlock.appendChild(repositoryInfoIcon);

        const repositoryDownloadIcon = document.createElement('a');
        repositoryDownloadIcon.className = "repository-download-icon";
        repositoryDownloadIcon.addEventListener('click', () => {
            if(type == 'WhitePaper') window.open('https://github.com/timestopeofficial/bitcoinkrypton-repository/blob/main/whitepaper/BitcoinKrypton-Whitepaper-'+Object.values(items[index])+'.pdf');
            else if(type == 'SourceCode') window.open('https://github.com/timestopeofficial/bitcoinkrypton-'+Object.keys(items[index]));
        });

        repositoryBlock.appendChild(repositoryIcon);
        repositoryBlock.appendChild(repositoryInfoBlock);
        repositoryBlock.appendChild(repositoryDownloadIcon);

        repositoryElement.appendChild(repositoryBlock);          
    }
    $('#repository').append(repositoryElement);
}

const addRepositoryElement = (type, items) => {
    const repositoryElement = document.createElement('div');
    repositoryElement.className = "repository-element";

    const repositoryTitle = document.createElement('div');
    repositoryTitle.className = "repository-title";
    
    if(type == 'WhitePaper') repositoryTitle.setAttribute('text-lang', "whitepaper-title");
    else repositoryTitle.innerHTML = "Bitcoin TimeStope's " + type;
    repositoryElement.appendChild(repositoryTitle);

    const repositoryBlock = document.createElement('div');
    repositoryBlock.className = "repository-block";

    const repositoryIcon = document.createElement('div');
    repositoryIcon.className = type == 'WhitePaper' ? "repository-pdf-icon" : "repository-source-code-icon";
    repositoryBlock.appendChild(repositoryIcon);

    for (let index = 0; index < items.length; index++) {
        const repositoryDownloadIcon = document.createElement('a');
        repositoryDownloadIcon.className = "repository-download-icon";
        if(type == 'WhitePaper') repositoryDownloadIcon.setAttribute('text-lang', 'lang-text-'+Object.keys(items[index]));
        else repositoryDownloadIcon.innerHTML = Object.values(items[index]);
        repositoryDownloadIcon.addEventListener('click', () => {
            if(type == 'WhitePaper') window.open('https://github.com/timestopeofficial/bitcoinkrypton-repository/blob/main/whitepaper/BitcoinKrypton-Whitepaper-'+Object.values(items[index])+'.pdf');
            else if(type == 'SourceCode') window.open('https://github.com/timestopeofficial/bitcoinkrypton-'+Object.keys(items[index]));
        });
        repositoryBlock.appendChild(repositoryDownloadIcon);
    }

    repositoryElement.appendChild(repositoryBlock);          
    $('#repository').append(repositoryElement);
}

const Repository = () => {
    useEffect(() => {
        $('#repository').empty();
        // addRepositoryElement('WhitePaper', JSON.parse(localStorage.languages))
        addRepositoryElement('SourceCode', [{'seed':"Seed"}, {'wallet':"Wallet"}, {'pool':"Pool"}])
        _setLanguage();

        $(".headerMenu_Home").removeClass("selected");
        $(".headerMenu_How").removeClass("selected");
        $(".headerMenu_Repository").addClass("selected")
    }, [])
    return (
        <>
        <div id="repository"></div>
        </>
    );
};

export default Repository;