import React from 'react';
import { Link } from 'react-router-dom';

const clickMenu = (menuName) => {
  let menuUrl = null
  if (menuName == 'wallet') menuUrl = 'https://wallet' + process.env.REACT_APP_DOMAIN_URL;
  else if (menuName == 'explorer') menuUrl = 'https://explorer' + process.env.REACT_APP_DOMAIN_URL;
  else if (menuName == 'youtube') menuUrl = localStorage.language == 'VI' ? 'https://www.youtube.com/@bitcoin-timestope-vietnam' : 'https://www.youtube.com/@bitcoin-timestope-global';
  else if (menuName == 'discord') menuUrl = 'https://discord.gg/xUvKZBxs';
  else if (menuName == 'telegram') menuUrl = 'https://t.me/bitcointimestope_community';
  else if (menuName == 'facebook') menuUrl = 'https://www.facebook.com/bitcoin.timestope';
  else if (menuName == 'instagram') menuUrl = 'https://www.instagram.com/time_global/';
  else if (menuName == 'threads') menuUrl = 'https://www.threads.net/@time_global';
  else if (menuName == 'x') menuUrl = 'https://x.com/btc_timestope';

  if(menuName == 'wallet' || menuName == 'explorer' ) window.location.replace(menuUrl);
  else window.open(menuUrl);
}

const Footer = () => {
  return (
    <>
      <div id="footer">
        <p className="icon-timestope"></p>
        <div className="footer-list footer-info">
          <p>INFO</p>
          <div className="footer-list-item">
            <Link to='/home' text-lang="menu-home">Home</Link>
            <a text-lang="menu-wallet" onClick={() => clickMenu('wallet')}>Wallet/ Miner</a>
            <a onClick={() => clickMenu('explorer')}>Explorer</a>
            <Link to='/guide' text-lang="mode-guide">Guide</Link>
            <Link to='/repository' text-lang="menu-repository">Repository</Link>
          </div>
        </div>
        <div className="icon-move-top" onClick={() => window.scroll({ top: 0 })}></div>
        <div className="footer-list footer-sns">
          <p>SNS</p>
          <div className="footer-list-item">
            <div className='footer-list-item-youtube' onClick={() => clickMenu('youtube')}>Youtube</div>
            <div className='footer-list-item-discord' onClick={() => clickMenu('discord')}>Discord</div>
            <div className='footer-list-item-telegram' onClick={() => clickMenu('telegram')}>Telegram</div>
            <div className='footer-list-item-facebook' onClick={() => clickMenu('facebook')}>Facebook</div>
            <div className='footer-list-item-instagram' onClick={() => clickMenu('instagram')}>Instagram</div>
            <div className='footer-list-item-threads' onClick={() => clickMenu('threads')}>Threads</div>
            <div className='footer-list-item-x' onClick={() => clickMenu('x')}>X</div>
          </div>
        </div>
        <div className="icon-background"></div>
      </div>
    </>
  );
};

export default Footer;